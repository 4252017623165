.cardLink {
    text-decoration: none;
    color: inherit;
    display: block;
    height: 100%;
}

.blogCard {
    height: 100%;
    display: flex;
    flex-direction: column;
    transition: transform 0.2s ease;
}

.imageWrapper {
    width: 100%;
    height: 240px;
    overflow: hidden;
}

.imageWrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.content {
    flex: 1;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.date {
    color: #666;
    font-size: 0.9rem;
}

.content h3 {
    font-size: 1.25rem;
    margin: 0;
    line-height: 1.4;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.content p {
    color: #666;
    line-height: 1.6;
    margin: 0;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
