.blogPost {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
}

.header {
    margin-bottom: 2rem;
}

.backButton {
    display: inline-block;
    margin-bottom: 1rem;
    color: #666;
    text-decoration: none;
    transition: color 0.2s;
}

.backButton:hover {
    color: #000;
}

.date {
    color: #666;
    margin-top: 0.5rem;
}

.grid {
    display: grid;
    grid-template-columns: 2fr 3fr;
    align-items: start;
    position: relative;
    z-index: 1;
    gap: 2rem;
}

.imageContainer {
    display: block;
    width: 100%;
    margin: 2rem 0 0 2rem;
    order: 1;
}

.imageContainer img {
    width: 100%;
    height: 100%;
    /* border-radius: 12px; */
}

.textContent {
    font-size: clamp(1rem, 2vw, 1.5rem);
    line-height: 1.8;
    color: #333;
    margin: 0 2rem;
    order: 2;
}

.textContent p {
    margin-bottom: 1.5rem;
}

.metadata {
    display: flex;
    gap: 1rem;
    color: #666;
    margin-bottom: 1rem;
    font-size: 0.9rem;
}

.subtitle {
    font-size: 1.5rem;
    color: #666;
    margin-top: 0.5rem;
    font-weight: normal;
    text-align: left;
}

.tags {
    display: flex;
    gap: 0.5rem;
    margin-top: 2rem;
    flex-wrap: wrap;
}

.tag {
    background: #f0f0f0;
    padding: 0.5rem 1rem;
    border-radius: 20px;
    font-size: 0.9rem;
    color: #666;
}

.slideshow {
    width: 100%;
    height: 100%;
    min-height: 200px;
    /* border-radius: 12px; */
    /* overflow: hidden; */
}

/* Add responsive layout for mobile */
@media (max-width: 768px) {
    .grid {
        grid-template-columns: 1fr;
    }

    .imageContainer {
        order: -1;
        margin: 0;
        padding: 2rem 2rem 0 2rem;
    }

    .textContent {
        margin: 0;
        padding: 0 2rem;
    }
}
