.button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0.75rem 1.5rem;
    border: 2px solid var(--color-black);
    border-radius: 8px;
    font-weight: 500;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.3s ease;
    box-shadow: 2px 2px 0 rgba(44, 86, 255, 0.8);
    white-space: nowrap;
}

/* Normal state */
.primary {
    background: var(--color-white);
    color: var(--color-black);
}

.secondary {
    background: transparent;
    color: var(--color-black);
}

/* Hover state */
.button:hover {
    background: var(--color-black);
    color: var(--color-white);
    box-shadow: 2px 2px 0 rgba(44, 86, 255, 0.8);
}

/* Clicked/Active state */
.button:active {
    background: var(--color-black);
    color: var(--color-white);
    transform: translate(2px, 2px);
    box-shadow: none;
}

.selected {
    background: var(--color-scheme-blue);
    color: var(--color-black);
    box-shadow: 2px 2px 0 rgba(44, 86, 255, 0.8);
}

.selected:hover {
    background: var(--color-black);
    color: var(--color-white);
}

/* Sizes */
.small {
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
}

.medium {
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
}

.large {
    padding: 1rem 2rem;
    font-size: 1.125rem;
}

/* Icon button variant - overriding default button styles */
.icon {
    padding: 0.5rem;
    width: 40px;
    height: 40px;
    box-shadow: none;
    transform: none;
}

.icon:hover {
    background: var(--color-black);
    color: var(--color-white);
    transform: none;
    box-shadow: none;
}

.icon:active {
    background: var(--color-scheme-blue);
    color: var(--color-black);
    transform: none;
    box-shadow: none;
}

.iconLarge {
    padding: 0.5rem;
    width: 120px;
    height: 60px;
    box-shadow: none;
    transform: none;
}

.iconLarge:hover {
    background: var(--color-black);
    color: var(--color-white);
    transform: none;
    box-shadow: none;
}

.iconLarge:active {
    background: var(--color-scheme-blue);
    color: var(--color-black);
    transform: none;
    box-shadow: none;
}

/* Tablet */
@media (max-width: 1024px) {
    .iconLarge {
        width: 100px;
        height: 50px;
        font-size: 0.75rem;
    }
}

/* Mobile */
@media (max-width: 768px) {
    .iconLarge {
        width: 80px;
        height: 40px;
        font-size: 0.5rem;
    }
}
