.card {
    background: white;
    border: 2px solid #000;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 4px 4px 0 rgba(0, 0, 0, 0.8);
}

.hover:hover {
    transform: translateY(-2px);
    box-shadow: 4px 4px 0 rgba(0, 0, 0, 0.8);
    transition: all 0.2s ease;
}

.interactive {
    cursor: pointer;
}

.interactive:active {
    transform: translate(2px, 2px);
    box-shadow: 2px 2px 0 rgba(0, 0, 0, 0.8);
}

.clickable {
    cursor: pointer;
}
