.spinner {
    border: 16px solid #f3f3f3;
    border-top: 16px solid var(--color-scheme-red);
    border-left: 16px solid var(--color-scheme-yellow);
    border-right: 16px solid var(--color-scheme-green);
    border-bottom: 16px solid var(--color-scheme-blue);
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
    position: fixed;
    top: 40%;
    left: 45%;
    transform: translate(-50%, -50%);
    z-index: 9999;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
