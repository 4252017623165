/* Import Noto Sans TC (Traditional Chinese) from Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@400;500;700&display=swap');

:root {
    --font-family-en: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
    --font-family-zh: 'Noto Sans TC', sans-serif;
}

/* Apply fonts based on language */
[lang='en'] {
    font-family: var(--font-family-en);
}

[lang='zh'] {
    font-family: var(--font-family-zh);
}

body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

* {
    box-sizing: border-box;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
