.slideshow {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 400px;
    overflow: hidden;
}

.slideshowImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.active {
    opacity: 1;
}

.dots {
    position: absolute;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 0.5rem;
    z-index: 1;
}

.dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.5);
    border: none;
    cursor: pointer;
    padding: 0;
    transition: background-color 0.3s ease;
}

.activeDot {
    background: white;
}

/* Mobile styles */
@media (max-width: 768px) {
    .slideshow {
        min-height: 300px;
    }

    .indicators {
        bottom: 10px;
    }

    .indicator {
        width: 8px;
        height: 8px;
    }
}

/* Ensure images maintain aspect ratio */
@supports (aspect-ratio: 16/9) {
    .slideshow {
        aspect-ratio: 16/9;
        min-height: auto;
    }
}