.grid {
    display: grid;
    gap: 2rem;
    margin-top: 3rem;
}

.columns1 {
    grid-template-columns: 1fr;
}

.columns2 {
    grid-template-columns: repeat(2, 1fr);
}

.columns3 {
    grid-template-columns: repeat(3, 1fr);
}

.columns4 {
    grid-template-columns: repeat(4, 1fr);
}

@media (max-width: 1024px) {
    .grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 768px) {
    .grid {
        grid-template-columns: 1fr;
    }
}
