/* Remove .module from filename - we want this to be a regular CSS file */
:root {
    /* Primary Colors */
    --color-scheme-blue: #D3DCFF;
    --color-scheme-red: #FADCDC;
    --color-scheme-green: #CCF4EA;
    --color-scheme-yellow: #FFF1B8;

    /* Hover States */
    --color-scheme-blue-hover: #B1BEFF;
    --color-scheme-red-hover: #F8C4C4;
    --color-scheme-green-hover: #A9EFE0;
    --color-scheme-yellow-hover: #FFE88F;

    /* Common Colors */
    --color-black: #000000;
    --color-blue: #2C56FF;
    --color-gray: #F5F5F5;
    --color-white: #FFFFFF;
    --color-text-primary: #333333;
    --color-text-secondary: #666666;
}
